import React from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus,
  faRobot,
  faUserMd,
  faUsers,
  faIdCard,
  faUserCheck,
  faBrain,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

export const HowItWorks = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("forPatients");

  const categories = [
    { category: "forPatients", label: "For Patients" },
    { category: "forDoctors", label: "For Professionals" }
  ];

  const timelineData = {
    forPatients: [
      {
        icon: faUserPlus,
        title: "Sign Up and Verify Your Profile",
        description: "Complete a quick registration and provide initial health details for better matching."
      },
      {
        icon: faRobot,
        title: "Talk to Our AI Health Assistant",
        description: "Log your symptoms, receive initial insights, and prepare for your doctor consultation."
      },
      {
        icon: faUserMd,
        title: "Book Your Doctor",
        description: "Choose from our recommended specialists based on your needs."
      },
      {
        icon: faUsers,
        title: "Join a Community",
        description: "After a doctor's verification, join a support group tailored to your health condition."
      }
    ],
    forDoctors: [
      {
        icon: faIdCard,
        title: "Create Your Profile",
        description: "Highlight your specialties and set your availability."
      },
      {
        icon: faUserCheck,
        title: "Receive Patient Matches",
        description: "Our algorithm ensures you connect with the right patients."
      },
      {
        icon: faBrain,
        title: "Leverage AI Assistance",
        description: "Use our tools to transcribe, diagnose, and manage your consultations effectively."
      },
      {
        icon: faCheckCircle,
        title: "Verify and Recommend",
        description: "Help patients join communities that can further support their recovery journey."
      }
    ]
  };

  return (
    <section className="project" id="how-it-works">
      <Container>
        <Row>
          <Col size={12}>
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>How It Works</h2>
                  <p>Follow these simple steps to get started with Dr.Medkit</p>
                  
                  <Nav variant="pills" className="category-nav mb-4 justify-content-center align-items-center">
                    <Nav.Item>
                      <Nav.Link 
                        className={`category-nav-link ${selectedCategory === "forPatients" ? 'active' : ''}`}
                        onClick={() => setSelectedCategory("forPatients")}
                      >
                        <span>For Patients</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link 
  className={`category-nav-link ${selectedCategory === "forDoctors" ? 'active' : ''}`}
  onClick={() => setSelectedCategory("forDoctors")}
  data-category="forDoctors"
>
  <span>For Professionals</span>
</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <div className="timeline-container">
                    {timelineData[selectedCategory].map((item, index) => (
                      <div className="timeline-item" key={index}>
                        <div className="timeline-icon">
                          <FontAwesomeIcon icon={item.icon} className="white-icon" />
                        </div>
                        <div className="timeline-content">
                          <h3>{item.title}</h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>}
              </TrackVisibility>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const styles = `
  <style>
    .category-nav {
      width: 95%;
      margin: 0 auto;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      overflow: hidden;
      padding: 10px;
      display: flex;
      flex-direction: row !important;
      justify-content: center;
      gap: 20px;
    }

    .category-nav .nav-item {
      flex: 0 0 auto;
      margin: 0 10px;
    }

    .category-nav-link {
      border-radius: 25px !important;
      padding: 15px 30px !important;
      font-size: 16px !important;
      line-height: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff !important;
      background-color: transparent !important;
      transition: all 0.3s ease;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
    }

    .category-nav-link::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      transition: all 0.3s ease;
      border-radius: 25px;
    }

    .category-nav-link:hover::before,
    .category-nav-link.active::before {
      width: 100%;
    }

    .category-nav-link span {
      position: relative;
      z-index: 1;
    }

    .category-nav-link:hover,
    .category-nav-link.active {
      transform: translateY(-3px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .category-nav-link:active {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .timeline-container {
      position: relative;
      max-width: 800px;
      margin: 0 auto;
      padding: 40px 0;
    }

    .timeline-container::after {
      content: '';
      position: absolute;
      width: 2px;
      background: rgba(255, 255, 255, 0.2);
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1px;
    }

    .timeline-item {
      padding: 10px 40px;
      position: relative;
      width: 50%;
      animation: slideIn 0.8s ease-in-out forwards;
      opacity: 0;
    }

    .timeline-item:nth-child(odd) {
      left: 0;
      text-align: right;
    }

    .timeline-item:nth-child(even) {
      left: 50%;
      text-align: left;
    }

    .timeline-icon {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #007bff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 1;
    }

    .timeline-item:nth-child(odd) .timeline-icon {
      right: -20px;
    }

    .timeline-item:nth-child(even) .timeline-icon {
      left: -20px;
    }

    .timeline-content {
      padding: 20px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
      margin: 0 20px;
    }

    .timeline-content h3 {
      color: #ffffff;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .timeline-content p {
      color: #B8B8B8;
      font-size: 16px;
      margin: 0;
    }

    @keyframes slideIn {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .timeline-item:nth-child(1) { animation-delay: 0.2s; }
    .timeline-item:nth-child(2) { animation-delay: 0.4s; }
    .timeline-item:nth-child(3) { animation-delay: 0.6s; }
    .timeline-item:nth-child(4) { animation-delay: 0.8s; }

    @media (max-width: 768px) {
      .timeline-container::after {
        left: 31px;
      }
      
      .timeline-item {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
      }

      .timeline-item:nth-child(odd) {
        left: 0;
        text-align: left;
      }

      .timeline-item:nth-child(even) {
        left: 0;
      }

      .timeline-item:nth-child(odd) .timeline-icon {
        left: 11px;
        right: auto;
      }
    }

    @media (max-width: 768px) {
      .category-nav {
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 10px;
      }
      
      .category-nav-link {
        width: auto;
        padding: 12px 20px !important;
      }
    }
  </style>
`;

export default () => (
  <>
    <HowItWorks />
    <div dangerouslySetInnerHTML={{ __html: styles }} />
  </>
);
