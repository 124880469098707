import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { WhoAreWe } from "./components/WhoAreWe";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Benefits } from "./components/Benefits";
import { WhyChoose } from "./components/WhyChoose";
import { HowItWorks } from "./components/HowItWorks";
import backgroundImage from './assets/img/5.png';

function App() {
  return (
    <div className="App" style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      minHeight: '100vh'
    }}>
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Benefits />
      <WhyChoose />
      <HowItWorks />
      <WhoAreWe />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
