import { useState, useEffect } from "react";
import { Col, Row, Alert, Button } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(false);
  const emailAddress = "info@drmedkit.com";

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>Feel free to contact with us:</h3>
              <p></p>
              {status === 'sending' && <Alert>Sending...</Alert>}
              {status === 'error' && <Alert variant="danger">{message}</Alert>}
              {status === 'success' && <Alert variant="success">{message}</Alert>}
                <a 
                  href={`mailto:${emailAddress}`}
                  className="newsletter-button"
                  onClick={handleClick}>
                  {emailAddress}
                </a>
            </Col>
          </Row>
        </div>
      </Col>
  )
}
